import {flare} from '@flareapp/flare-client';
import {arrayIncludesAny} from "@/utilities";

if (import.meta.env.PROD) {
    flare.light();
}

flare.beforeSubmit = (report) => {


    if ([
        'this._map',
        'Request failed with status code 401',
        '_latLngToNewLayerPoint',
        'Network Error',
        'canceled',
        'is not a valid JavaScript MIME type',
        'Cannot create proxy with a non-object as target or handler',
    ].some(v => report.message.includes(v))) {
        return false;
    }


    if (filterUserAgent(report) === false) {
        return false;
    }

    return report;
};

function filterUserAgent(report) {
    const userAgent = report.context.request.useragent;
    // Don't send errors from bots
    return !arrayIncludesAny([userAgent], ['adsbot', 'googlebot']);
}

export default {
    flare,
};
