/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import formKitTheme from '@/formkitTheme';
import {createAutoAnimatePlugin, createAutoHeightTextareaPlugin} from '@formkit/addons';
import {nl} from '@formkit/i18n';
import {generateClasses} from '@formkit/themes';
import {defaultConfig as FKdc, plugin as FKp} from '@formkit/vue';
import {createInertiaApp,} from '@inertiajs/vue3';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import Notifications from 'notiwind';
import {createPinia} from 'pinia';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-teal/theme.css';
import {createApp, h} from 'vue';
import {createI18n} from 'vue-i18n';
import '../css/app.css';
import './bootstrap';
import dayjs from './plugins/dayjs';
import flare from './plugins/flare';
import {ZiggyVue} from './plugins/ziggy';
import primeVueTheme from './primveVueTheme';
import localeMessages from './vue-i18n-locales.generated';
import {Ziggy} from './ziggy';
import * as Sentry from "@sentry/vue";

import AutoComplete from 'primevue/autocomplete';


const pinia = createPinia();

const numberFormats = {
    en: {
        currency: {
            style: 'currency',
            currency: 'EUR',
        },
        percent: {
            style: 'percent',
            useGrouping: true,
        },
    },
};

createInertiaApp({
    progress: {color: '#ea580c', delay: 500},
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({el, App, props, plugin}) {
        const currentLocale = props.initialPage.props.locale;

        const i18n = createI18n({
            numberFormats,
            legacy: false,
            locale: currentLocale,
            fallbackLocale: 'en',
            messages: localeMessages,
        });

        const app = createApp({render: () => h(App, props)});


        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: false,
                }),
                Sentry.feedbackIntegration({
                    colorScheme: "system",
                    showName: false,
                    showEmail: false,
                    enableScreenshot: false,
                    messagePlaceholder: 'What\'s the bug? What did you expect? What happened instead? Please include as many references as possible. ' +
                        'For example if you are missing a NOA please include the awb number. ' +
                        'If someone did get a loading list include the email and the trip reference, etc.',
                }),

            ],
            environment: import.meta.env.APP_ENV,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        app.use(plugin)
            .use(pinia)
            .use(PrimeVue, {unstyled: true, pt: primeVueTheme})
            .component('AutoComplete', AutoComplete)
            .use(flare)
            .use(Notifications)
            .use(ZiggyVue, {...Ziggy, locale: currentLocale})
            .use(dayjs, {locale: currentLocale})
            .use(i18n)

            .use(
                FKp,
                FKdc({
                    config: {
                        classes: generateClasses(formKitTheme),
                    },
                    plugins: [createAutoAnimatePlugin({duration: 150}), createAutoHeightTextareaPlugin()],
                    locales: {nl},
                    locale: 'nl',
                    globalInjection: true,
                })
            )
            .mount(el);

        return app;
    },
});

