export default {
    "en": {
        "": "",
        "#": "#",
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more error)|(and {count} more errors)|(and {count} more errors)",
        "+": "+",
        "+1": "+1",
        "-": "-",
        "6-Digit code": "6-Digit code",
        "404 - Page not found": "404 - Page not found",
        "{activity} the {entity}": "{activity} the {entity}",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{count} characters": "{count} characters",
        "{day} from": "{day} from",
        "{model} {action}": "{model} {action}",
        "{model} {number}": "{model} {number}",
        "{number} departed from {location} at {time} but seems to has stopped in transit. Please check tracking.": "{number} departed from {location} at {time} but seems to has stopped in transit. Please check tracking.",
        "{number} was planned {hours} later than original requested time. Please check.": "{number} was planned {hours} later than original requested time. Please check.",
        "{number} would start loading at {time} at {location}, but we noticed that it has not yet left. Can you indicate the implications of arrival time?": "{number} would start loading at {time} at {location}, but we noticed that it has not yet left. Can you indicate the implications of arrival time?",
        "{uldCode} is already assigned to {airWaybillNumber} by {userName} at {time}. Please reassign or delete pmc first": "{uldCode} is already assigned to {airWaybillNumber} by {userName} at {time}. Please reassign or delete pmc first",
        "{uld_code} : {air_waybill_number}": "{uld_code} : {air_waybill_number}",
        "{variant} image": "{variant} image",
        "Ability": "Ability",
        "Ability model": "Ability model",
        "About us": "About us",
        "Accept Invitation": "Accept Invitation",
        "According to Track & Trace, the cargo didn’t departed yet.": "According to Track & Trace, the cargo didn’t departed yet.",
        "Account": "Account",
        "A comma separated list of mail addresses.": "A comma separated list of mail addresses.",
        "Action": "Action",
        "Activated": "activated",
        "activated": "activated",
        "Active": "Active",
        "Add": "Add",
        "Add {model}": "Add {model}",
        "Add additional security to your account using two factor authentication.": "Add additional security to your account using two factor authentication.",
        "Add air waybill": "Add air waybill",
        "Add a new team member to your team, allowing them to collaborate with you.": "Add a new team member to your team, allowing them to collaborate with you.",
        "Add as a comma separated list of mail addresses.": "Add as a comma separated list of mail addresses.",
        "Add Comment": "Add Comment",
        "Add comment": "Add comment",
        "Added.": "Added.",
        "Add line": "Add line",
        "Add member": "Add member",
        "Add menu item": "Add menu item",
        "Add message": "Add message",
        "add one": "add one",
        "Add page block": "Add page block",
        "Add pallet": "Add pallet",
        "Add piece": "Add piece",
        "Address": "Address",
        "address": "address",
        "Addressable": "Addressable",
        "Addresses": "Addresses",
        "Add Stop #": "Add Stop #",
        "Add Team Member": "Add Team Member",
        "Add to cart": "Add to cart",
        "Add to favorites": "Add to favorites",
        "Add to shopping cart": "Add to shopping cart",
        "A decryption key is required.": "A decryption key is required.",
        "Administrator": "Administrator",
        "Administrator users can perform any action.": "Administrator users can perform any action.",
        "A first requested time between {firstDatetime} and {lastDatetime}": "A first requested time between {firstDatetime} and {lastDatetime}",
        "A first requested time of {datetime}": "A first requested time of {datetime}",
        "Airport code": "Airport code",
        "Air Waybill": "Air Waybill",
        "Air waybill": "Air waybill",
        "Air waybill {airWaybillNumber} already created this run": "Air waybill {airWaybillNumber} already created this run",
        "Air waybill {airWaybillNumber} already exists": "Air waybill {airWaybillNumber} already exists",
        "Air waybill {airWaybillNumber} created": "Air waybill {airWaybillNumber} created",
        "Air Waybill {number} pieces": "Air Waybill {number} pieces",
        "Air Waybill number": "Air Waybill number",
        "Air waybill number": "Air waybill number",
        "Air Waybill pieces": "Air Waybill pieces",
        "Air Waybill pieces on {uldCode}": "Air Waybill pieces on {uldCode}",
        "Air Waybill pieces on loose": "Air Waybill pieces on loose",
        "Air Waybills": "Air Waybills",
        "Air waybills": "Air waybills",
        "Air Waybills on package": "Air Waybills on package",
        "Air Waybills on package {uldCode}": "Air Waybills on package {uldCode}",
        "Air Waybill Warehouse Overview": "Air Waybill Warehouse Overview",
        "Aliases": "Aliases",
        "All": "All",
        "All {models}": "All {models}",
        "All blogs": "All blogs",
        "All clients": "All clients",
        "All handlers": "All handlers",
        "All notification": "All notification",
        "All of the people that are part of this team.": "All of the people that are part of this team.",
        "Allowed arrival deviation": "Allowed arrival deviation",
        "Allowed mail addresses": "Allowed mail addresses",
        "Allowed mail addresses for Noa Mail": "Allowed mail addresses for Noa Mail",
        "Allowed requested arrival deviation": "Allowed requested arrival deviation",
        "All Products": "All Products",
        "All products": "All products",
        "All questions": "All questions",
        "All rights reserved.": "All rights reserved.",
        "All statuses": "All statuses",
        "All warehouses": "All warehouses",
        "Already registered?": "Already registered?",
        "Alt text": "Alt text",
        "Always request 3 meter height": "Always request 3 meter height",
        "A message will be send to this/these handlers notifying them to discard this reference.": "A message will be send to this/these handlers notifying them to discard this reference.",
        "Amount": "Amount",
        "Amount of products": "Amount of products",
        "An error occurred": "An error occurred",
        "An error occurred in the application:": "An error occurred in the application:",
        "A new incident report has been created.": "A new incident report has been created.",
        "A new verification link has been sent to the email address you provided in your profile settings.": "A new verification link has been sent to the email address you provided in your profile settings.",
        "A new verification link has been sent to your email address.": "A new verification link has been sent to your email address.",
        "Answer": "Answer",
        "A piece": "A piece",
        "API Token": "API Token",
        "API Token Permissions": "API Token Permissions",
        "API Tokens": "API Tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "API tokens allow third-party services to authenticate with our application on your behalf.",
        "Apply discount code": "Apply discount code",
        "Are you sure you want retract the membership of {model}?": "Are you sure you want retract the membership of {model}?",
        "Are you sure you want to delete {model}?": "Are you sure you want to delete {model}?",
        "Are you sure you want to delete this {model}?": "Are you sure you want to delete this {model}?",
        "Are you sure you want to delete this air waybill package?": "Are you sure you want to delete this air waybill package?",
        "Are you sure you want to delete this file?": "Are you sure you want to delete this file?",
        "Are you sure you want to delete this network?": "Are you sure you want to delete this network?",
        "Are you sure you want to delete this package?": "Are you sure you want to delete this package?",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.",
        "Are you sure you would like to delete this API token?": "Are you sure you would like to delete this API token?",
        "Are you sure you would like to leave this team?": "Are you sure you would like to leave this team?",
        "Are you sure you would like to remove this person from the team?": "Are you sure you would like to remove this person from the team?",
        "Arrival at": "Arrival at",
        "Arrival truck": "Arrival truck",
        "Ascension day": "Ascension day",
        "Assign": "Assign",
        "Assigned": "Assigned",
        "Assign fyco": "Assign fyco",
        "Assign fyco parcels": "Assign fyco parcels",
        "Assignment Status": "Assignment Status",
        "Assign user": "Assign user",
        "ATA": "ATA",
        "Attach Air Waybill": "Attach Air Waybill",
        "Attached is the data export.": "Attached is the data export.",
        "Attached is the QLS data export for {date}.": "Attached is the QLS data export for {date}.",
        "Attachments": "Attachments",
        "Attach package": "Attach package",
        "Author": "Author",
        "Auto": "Auto",
        "Automation": "Automation",
        "AWB": "AWB",
        "AWB no.": "AWB no.",
        "AWB status": "AWB status",
        "Back": "Back",
        "Back and mark as done": "Back and mark as done",
        "Back and release": "Back and release",
        "Back to home": "Back to home",
        "Back to the shopping cart": "Back to the shopping cart",
        "Backup codes": "Backup codes",
        "Bags": "Bags",
        "Banner": "Banner",
        "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.",
        "Belgium": "Belgium",
        "Billing address": "Billing address",
        "Blog": "Blog",
        "Blogs": "Blogs",
        "Blog Settings": "Blog Settings",
        "Blue Button": "Blue Button",
        "Both packages and air waybill pieces are known but not linked": "Both packages and air waybill pieces are known but not linked",
        "Browser Sessions": "Browser Sessions",
        "BTW number": "BTW number",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Buttons": "Buttons",
        "By subscribing you agree to receive our newsletter.": "By subscribing you agree to receive our newsletter.",
        "Cancel": "Cancel",
        "Can you load on other times, or do you have any other questions/suggestions?": "Can you load on other times, or do you have any other questions/suggestions?",
        "Cargo": "Cargo",
        "Categories": "Categories",
        "Category": "Category",
        "Category Settings": "Category Settings",
        "Category slider": "Category slider",
        "Change": "Change",
        "Change password": "Change password",
        "Changes": "Changes",
        "Changing the status will not notify the transporter, this needs to be done manually.": "Changing the status will not notify the transporter, this needs to be done manually.",
        "Check": "Check",
        "Checklist": "Checklist",
        "Checkout": "Checkout",
        "Checkout page": "Checkout page",
        "Choose link type": "Choose link type",
        "City": "City",
        "Claim": "Claim",
        "Claim {model}": "Claim {model}",
        "Claim trip {number}": "Claim trip {number}",
        "Click here to re-send the verification email.": "Click here to re-send the verification email.",
        "Click here to upload a file": "Click here to upload a file",
        "Client": "Client",
        "client": "Client",
        "Clients": "Clients",
        "Close": "Close",
        "Close conversation": "Close conversation",
        "Closed": "Closed",
        "closed": "closed",
        "CMR\\'s": "CMR\\'s",
        "CMR\\'s / POD\\'s": "CMR\\'s / POD\\'s",
        "CoC": "CoC",
        "Code": "Code",
        "code": "code",
        "Codes": "Codes",
        "Combined": "Combined",
        "Combined Pallets": "Combined Pallets",
        "Combine Pallet": "Combine Pallet",
        "Combine pallets": "Combine pallets",
        "Combines": "Combines",
        "Comma separated list of codes": "Comma separated list of codes",
        "Comma separated list of NOA fragments that should be present in the NOA email. Is case insensitive and whitespaces will be collapsed.": "Comma separated list of NOA fragments that should be present in the NOA email. Is case insensitive and whitespaces will be collapsed.",
        "Comment": "Comment",
        "Comments": "Comments",
        "Commerce settings": "Commerce settings",
        "Company details": "Company details",
        "Company name": "Company name",
        "Complete": "Complete",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Conditional Page link": "Conditional Page link",
        "Confirm": "Confirm",
        "Confirm Password": "Confirm Password",
        "Confirm password": "Confirm password",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Content": "Content",
        "Content (Collapsible)": "Content (Collapsible)",
        "Conversation": "Conversation",
        "Conversations": "Conversations",
        "Copied": "Copied",
        "Countdown free time for pickup": "Countdown free time for pickup",
        "Country": "Country",
        "Coupon": "Coupon",
        "Coupon applied": "Coupon applied",
        "Coupon can no longer be used": "Coupon can no longer be used",
        "Coupon not found": "Coupon not found",
        "Coupons": "Coupons",
        "Create": "Create",
        "Create {model}": "Create {model}",
        "Create Account": "Create Account",
        "Create address": "Create address",
        "Create Air Waybill": "Create Air Waybill",
        "Create an account": "Create an account",
        "Create a new team to collaborate with others on projects.": "Create a new team to collaborate with others on projects.",
        "Create API Token": "Create API Token",
        "Create client": "Create client",
        "created": "created",
        "Created.": "Created.",
        "Created at": "Created at",
        "Created at {createdAt}. Updated by {name} at {updatedAt}": "Created at {createdAt}. Updated by {name} at {updatedAt}",
        "Create follow-up": "Create follow-up",
        "Create Incident Report": "Create Incident Report",
        "Create Network": "Create Network",
        "Create new package": "Create new package",
        "Create New Team": "Create New Team",
        "Create non-rollerbed trip": "Create non-rollerbed trip",
        "Create order": "Create order",
        "Create package": "Create package",
        "Create Pallet": "Create Pallet",
        "Create Pickup": "Create Pickup",
        "Create pickup": "Create pickup",
        "Create Prioritized Air Waybill": "Create Prioritized Air Waybill",
        "Create Prioritized Air Waybills": "Create Prioritized Air Waybills",
        "Create stack": "Create stack",
        "Create stack trip": "Create stack trip",
        "Create Team": "Create Team",
        "Create transporter company": "Create transporter company",
        "Create trip": "Create trip",
        "Create warehouse": "Create warehouse",
        "Currently no stock": "Currently no stock",
        "Current Password": "Current Password",
        "Current password": "Current password",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}",
        "Customs cleared": "Customs cleared",
        "Dangerously high": "Dangerously high",
        "Dangerously high explanation": "Dangerously high explanation",
        "Dangerously Low": "Dangerously Low",
        "Dangerously low explanation": "Dangerously low explanation",
        "Dashboard": "Dashboard",
        "Data Export": "Data Export",
        "Date": "Date",
        "Date from": "Date from",
        "Date of birth": "Date of birth",
        "Date to": "Date to",
        "Deactivate": "Deactivate",
        "Deactivate air waybill on create": "Deactivate air waybill on create",
        "Deactivated": "deactivated",
        "deactivated": "deactivated",
        "Default consultant": "Default consultant",
        "Default trip height": "Default trip height",
        "Default warehouse address": "Default warehouse address",
        "De laatste trends": "De laatste trends",
        "Delete": "Delete",
        "Delete {model}": "Delete {model}",
        "Delete Account": "Delete Account",
        "Delete Air Waybill Package": "Delete Air Waybill Package",
        "Delete API Token": "Delete API Token",
        "deleted": "deleted",
        "Delete file": "Delete file",
        "Delete network": "Delete network",
        "Delete package": "Delete package",
        "Delete Team": "Delete Team",
        "Delivered": "Delivered",
        "DEP": "DEP",
        "Departure at": "Departure at",
        "Description": "Description",
        "description": "description",
        "Desktop": "Desktop",
        "Details": "Details",
        "Disable": "Disable",
        "Disable swiper": "Disable swiper",
        "Disabling a coupon will prevent it from being used": "Disabling a coupon will prevent it from being used",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "Distance (km)": "Distance (km)",
        "DLV pieces": "DLV pieces",
        "DLV Time": "DLV Time",
        "DLV time": "DLV time",
        "DLV time(s)": "DLV time(s)",
        "done": "done",
        "Done.": "Done.",
        "Don’t have access to your authenticator device ?": "Don’t have access to your authenticator device ?",
        "Double ULD Cost": "Double ULD Cost",
        "Down": "Down",
        "Download": "Download",
        "Download app": "Download app",
        "Download AWB": "Download AWB",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Download checklist": "Download checklist",
        "Download CMR": "Download CMR",
        "Download invoice": "Download invoice",
        "Download Loaded Excel": "Download Loaded Excel",
        "Download Loading List": "Download Loading List",
        "Download Loading List for {shipment}": "Download Loading List for {shipment}",
        "Download NOA": "Download NOA",
        "Download NOA ({dateTime})": "Download NOA ({dateTime})",
        "Download T1: {name}": "Download T1: {name}",
        "Download Truck Loading List": "Download Truck Loading List",
        "Download Truck Loading List for {shipment}": "Download Truck Loading List for {shipment}",
        "Download Unloaded Excel": "Download Unloaded Excel",
        "Driver name": "Driver name",
        "Due at": "Due at",
        "E-mail": "E-mail",
        "e-mail": "E-mail",
        "Earliest NOA time": "Earliest NOA time",
        "Easter monday": "Easter monday",
        "Easter sunday": "Easter sunday",
        "Edit": "Edit",
        "Edit {model}": "Edit {model}",
        "Edit account": "Edit account",
        "Edit Addresses": "Edit Addresses",
        "Edit air waybill": "Edit air waybill",
        "Edit Handler": "Edit Handler",
        "Edit milestones": "Edit milestones",
        "Editor": "Editor",
        "Editor users have the ability to read, create, and update.": "Editor users have the ability to read, create, and update.",
        "Edit package": "Edit package",
        "Edit Profile": "Edit Profile",
        "Email": "Email",
        "email": "email",
        "Email address": "Email address",
        "Email addresses to notify when NOA is found for unknown air waybill number": "Email addresses to notify when NOA is found for unknown air waybill number",
        "Email Password Reset Link": "Email Password Reset Link",
        "Empty Stack Cost": "Empty Stack Cost",
        "Empty ULD": "Empty ULD",
        "Enable": "Enable",
        "Enable transit documents upload": "Enable transit documents upload",
        "Encrypted environment file already exists.": "Encrypted environment file already exists.",
        "Encrypted environment file not found.": "Encrypted environment file not found.",
        "End": "End",
        "End time": "End time",
        "Ensure your account is using a long, random password to stay secure.": "Ensure your account is using a long, random password to stay secure.",
        "Enter mail addresses": "Enter mail addresses",
        "Enter notification text": "Enter notification text",
        "Enter your comment here": "Enter your comment here",
        "Enter your e-mail and we send you a reset link": "Enter your e-mail and we send you a reset link",
        "Environment file already exists.": "Environment file already exists.",
        "Environment file not found.": "Environment file not found.",
        "errors": "errors",
        "Estimated loading time": "Estimated loading time",
        "ETA": "ETA",
        "ETA truck": "ETA truck",
        "ETA unloading": "ETA unloading",
        "ETD": "ETD",
        "Exact": "Exact",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Expand reportables": "Expand reportables",
        "Export": "Export",
        "Export {model}": "Export {model}",
        "Export Handler Overview": "Export Handler Overview",
        "Export Incident Overview": "Export Incident Overview",
        "Export trip handler overview": "Export trip handler overview",
        "External Link": "External Link",
        "External Test": "External Test",
        "External Tester": "External Tester",
        "Extra Abilities": "Extra Abilities",
        "Extra Loading Address Cost": "Extra Loading Address Cost",
        "Facebook link": "Facebook link",
        "Faq": "Faq",
        "Faqs": "Faqs",
        "Favorites": "Favorites",
        "favorites": "favorites",
        "Featured Image": "Featured Image",
        "Female": "Female",
        "FFM check": "FFM check",
        "File": "File",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "Fill in this code in the app.": "Fill in this code in the app.",
        "Filter": "Filter",
        "Filters": "Filters",
        "Finalized": "Finalized",
        "Finance": "Finance",
        "Finished inbound scan": "Finished inbound scan",
        "Finished outbound scan": "Finished outbound scan",
        "Finish enabling two factor authentication.": "Finish enabling two factor authentication.",
        "First name": "First name",
        "First Noa Time": "First Noa Time",
        "First requested time": "First requested time",
        "First Stop": "First Stop",
        "First warehouse RCF received after": "First warehouse RCF received after",
        "Fixed": "Fixed",
        "Flat Fee Cost": "Flat Fee Cost",
        "Flight": "Flight",
        "flight": "flight",
        "Flight {flight} delayed, original ETA {originalArrivalTime}, current ETA {newArrivalTime}": "Flight {flight} delayed, original ETA {originalArrivalTime}, current ETA {newArrivalTime}",
        "Flight {model}": "Flight {model}",
        "Flight ATA": "Flight ATA",
        "Flight ETA": "Flight ETA",
        "Flight eta": "Flight eta",
        "Flight has not left LUX": "Flight has not left LUX",
        "Flight in sync": "Flight in sync",
        "Flight not found": "Flight not found",
        "Flight Number": "Flight Number",
        "Flight number": "Flight number",
        "Flight numbers": "Flight numbers",
        "Flights": "Flights",
        "Flight was delayed": "Flight was delayed",
        "Focal point": "Focal point",
        "Follow up to": "Follow up to",
        "Follow up tot": "Follow up tot",
        "Follow us": "Follow us",
        "Follow us on": "Follow us on",
        "Forbidden": "Forbidden",
        "Forgot password": "Forgot password",
        "Forgot password?": "Forgot password?",
        "Forgotten password?": "Forgotten password?",
        "Forgot your password?": "Forgot your password?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.",
        "Forgot your password? Send a password reset link": "Forgot your password? Send a password reset link",
        "Form submission": "Form submission",
        "Form submissions": "Form submissions",
        "For your security, please confirm your password to continue.": "For your security, please confirm your password to continue.",
        "Free": "Free",
        "FREE SHIPPING WITH ORDERS OVER €75": "FREE SHIPPING WITH ORDERS OVER €75",
        "Free time": "Free time",
        "Friday": "Friday",
        "From": "From",
        "From address": "From address",
        "From addresses": "From addresses",
        "From Status": "From Status",
        "From the authenticator app": "From the authenticator app",
        "Fuel Per KM Cost": "Fuel Per KM Cost",
        "Full Address": "Full Address",
        "Full truck load": "Full truck load",
        "Full Truck Load Cost": "Full Truck Load Cost",
        "Fyco": "Fyco",
        "Fyco parcels": "Fyco parcels",
        "Gallery": "Gallery",
        "General slider": "General slider",
        "Generate can take quite some time. The report will be send to your email address when it is done.": "Generate can take quite some time. The report will be send to your email address when it is done.",
        "Generate variants": "Generate variants",
        "Generating these overviews takes a while.": "Generating these overviews takes a while.",
        "Germany": "Germany",
        "Global": "Global",
        "Global overviews": "Global overviews",
        "Global role": "Global role",
        "Global settings": "Global settings",
        "Good friday": "Good friday",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "Go to page {page}": "Go to page {page}",
        "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-": "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-",
        "Great! You have accepted the invitation to join the {team} team.": "Great! You have accepted the invitation to join the {team} team.",
        "Group": "Group",
        "Handler": "Handler",
        "handler": "Handler",
        "Handler / Stop": "Handler / Stop",
        "Handler DLV": "Handler DLV",
        "Handler RCF": "Handler RCF",
        "Handler Reference": "Handler Reference",
        "Handlers": "Handlers",
        "Has CMR/POD": "Has CMR/POD",
        "Has DLV": "Has DLV",
        "Has image": "Has image",
        "Has multiple clients": "Has multiple clients",
        "Has unresolved incident report": "Has unresolved incident report",
        "Heading": "Heading",
        "Hello": "Hello",
        "Hello!": "Hello!",
        "Hi": "Hi",
        "Hide completed": "Hide completed",
        "Hide on page": "Hide on page",
        "High": "High",
        "Highest quality": "Highest quality",
        "High explanation": "High explanation",
        "Hits": "Hits",
        "Home": "Home",
        "Hoogste kwaliteit": "Hoogste kwaliteit",
        "I agree to the {terms_of_service} and {privacy_policy}": "I agree to the {terms_of_service} and {privacy_policy}",
        "I am new here": "I am new here",
        "Icon": "Icon",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If left empty, the page title will be used.": "If left empty, the page title will be used.",
        "If left empty, the title will be used.": "If left empty, the title will be used.",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you already have an account, you may accept this invitation by clicking the button below:": "If you already have an account, you may accept this invitation by clicking the button below:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "If you did not expect to receive an invitation to this team, you may discard this email.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:",
        "I have saved the backup codes": "I have saved the backup codes",
        "Image": "Image",
        "Image CTA": "Image CTA",
        "Images": "Images",
        "Inbound Air Waybills": "Inbound Air Waybills",
        "Inbounded scanned": "Inbounded scanned",
        "Inbound scanned": "Inbound scanned",
        "Incident rapport": "Incident rapport",
        "incident rapport": "incident rapport",
        "Incident Report": "Incident Report",
        "Incident report": "Incident report",
        "incident report": "incident report",
        "Incident report - {reportableName} - {type} - {createdAt}": "Incident report - {reportableName} - {type} - {createdAt}",
        "Incident report - {reportableType} {reportableName} - {type} - {createdAt}": "Incident report - {reportableType} {reportableName} - {type} - {createdAt}",
        "Incident report - {reportableType}{reportableName} - {type} - {createdAt}": "Incident report - {reportableType}{reportableName} - {type} - {createdAt}",
        "Incident report - {type}": "Incident report - {type}",
        "Incident report - {type} - {createdAt}": "Incident report - {type} - {createdAt}",
        "Incident Report Created": "Incident Report Created",
        "Incident Reports": "Incident Reports",
        "Include cancelled": "Include cancelled",
        "Include Completed": "Include Completed",
        "Include delivered": "Include delivered",
        "Include T1 on invoice": "Include T1 on invoice",
        "Including VAT": "Including VAT",
        "Index Page": "Index Page",
        "Inloggen": "Inloggen",
        "Inspection": "Inspection",
        "Instagram link": "Instagram link",
        "Instruction title": "Instruction title",
        "Internal comment": "Internal comment",
        "Internal comments": "Internal comments",
        "Introduction": "Introduction",
        "Invalid filename.": "Invalid filename.",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "Invalid phone number": "Invalid phone number",
        "Invoiceable": "Invoiceable",
        "Invoiced": "Invoiced",
        "Invoices": "Invoices",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "It will be send by email when done.": "It will be send by email when done.",
        "KVK number": "KVK number",
        "Label": "Label",
        "Label Button": "Label Button",
        "Language": "Language",
        "Last active": "Last active",
        "Last excel upload at: {time}": "Last excel upload at: {time}",
        "Last message on": "Last message on",
        "Last mile": "Last mile",
        "Last name": "Last name",
        "Last refresh was {quantity} {unit} ago": "Last refresh was {quantity} {unit} ago",
        "Last used": "Last used",
        "Latest noa time": "Latest noa time",
        "Latitude": "Latitude",
        "Lead Time": "Lead Time",
        "Lead time greater then 24 hours": "Lead time greater then 24 hours",
        "Lead Times": "Lead Times",
        "Leave": "Leave",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to auto-generate": "Leave empty to auto-generate",
        "Leave empty to generate a random code. Not editable after creation.": "Leave empty to generate a random code. Not editable after creation.",
        "Leave empty to place in the marketplace when requested": "Leave empty to place in the marketplace when requested",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Leave Team": "Leave Team",
        "length": "length",
        "Length Used": "Length Used",
        "License plate": "License plate",
        "License plate (pulled unit)": "License plate (pulled unit)",
        "License plate {action} for trip {trip}": "License plate {action} for trip {trip}",
        "License plate pulled unit": "License plate pulled unit",
        "License plates": "License plates",
        "Link Button": "Link Button",
        "Linkedin link": "Linkedin link",
        "Loaded Excel": "Loaded Excel",
        "Loading": "Loading",
        "Loading arrival time": "Loading arrival time",
        "Loading list email addresses": "Loading list email addresses",
        "Loading list notification trigger": "Loading list notification trigger",
        "Loading list send": "Loading list send",
        "Loading list settings": "Loading list settings",
        "Loading time": "Loading time",
        "Load pickup": "Load pickup",
        "Location": "Location",
        "location": "location",
        "Location {model}": "Location {model}",
        "Location at:": "Location at:",
        "Locations": "Locations",
        "Log in": "Log in",
        "Login": "Login",
        "Login / Register": "Login / Register",
        "Login to your account in our secure environment": "Login to your account in our secure environment",
        "Login with a recovery code.": "Login with a recovery code.",
        "Log Out": "Log Out",
        "Logout": "Logout",
        "Log Out Other Browser Sessions": "Log Out Other Browser Sessions",
        "Longitude": "Longitude",
        "Looks like that not all cargo was retrieved from Swissport. Please check.": "Looks like that not all cargo was retrieved from Swissport. Please check.",
        "Low": "Low",
        "Low explanation": "Low explanation",
        "Made by:": "Made by:",
        "Mail addresses": "Mail addresses",
        "Main variant": "Main variant",
        "Make main": "Make main",
        "Make your decision:": "Make your decision:",
        "Male": "Male",
        "Manage Account": "Manage Account",
        "Manage and log out your active sessions on other browsers and devices.": "Manage and log out your active sessions on other browsers and devices.",
        "Manage API Tokens": "Manage API Tokens",
        "Manage Role": "Manage Role",
        "Manage Team": "Manage Team",
        "Mark all as read": "Mark all as read",
        "Mark as done": "Mark as done",
        "Mark as fyco done": "Mark as fyco done",
        "Mark as fyco prepared": "Mark as fyco prepared",
        "Mark as not delivered": "Mark as not delivered",
        "Mark as prepared": "Mark as prepared",
        "Mark as read": "Mark as read",
        "Mark as unread": "Mark as unread",
        "Marketplace": "Marketplace",
        "Marketplace email addresses": "Marketplace email addresses",
        "Marketplace messages": "Marketplace messages",
        "Marketplace Settings": "Marketplace Settings",
        "Master reference number": "Master reference number",
        "Max users": "Max users",
        "Medical": "Medical",
        "Meer informatie": "Meer informatie",
        "Member": "Member",
        "Members": "Members",
        "membership": "membership",
        "Menu": "Menu",
        "Menu items": "Menu items",
        "Menus": "Menus",
        "Message": "Message",
        "Messages": "Messages",
        "Metric Name": "Metric Name",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Milestones": "Milestones",
        "Minimal order amount": "Minimal order amount",
        "Minimum height": "Minimum height",
        "Minimum height of {height} cm": "Minimum height of {height} cm",
        "Missing requested loading times": "Missing requested loading times",
        "Missing some headers \"{headers}\"": "Missing some headers \"{headers}",
        "Mobile": "Mobile",
        "Monday": "Monday",
        "More": "More",
        "More settings can be set in the Page Block Settings page": "More settings can be set in the Page Block Settings page",
        "Most sold": "Most sold",
        "Move": "Move",
        "Move package to another truck": "Move package to another truck",
        "Move to location": "Move to location",
        "Multiple DLV's": "Multiple DLV's",
        "Multiple NOA's": "Multiple NOA's",
        "Multiple Noa's": "Multiple Noa's",
        "Multiple Rcf's or Dlv's": "Multiple Rcf's or Dlv's",
        "Multiple RCR's": "Multiple RCR's",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "NA": "NA",
        "Name": "Name",
        "name": "name",
        "Name client": "Name client",
        "NCTS code": "NCTS code",
        "Nederland": "Nederland",
        "Netherlands": "Netherlands",
        "Network": "Network",
        "network": "Network",
        "Network(s)": "Network(s)",
        "Networks": "Networks",
        "Newest": "Newest",
        "New Password": "New Password",
        "New password": "New password",
        "New URL": "New URL",
        "Next": "Next",
        "No": "No",
        "No {model}": "No {model}",
        "No {model} selected": "No {model} selected",
        "No {models} left to add": "No {models} left to add",
        "NOA": "NOA",
        "NOA email addresses": "NOA email addresses",
        "NOA fragments": "NOA fragments",
        "No air waybills left": "No air waybills left",
        "Noa Mail Settings": "Noa Mail Settings",
        "NOA Received": "NOA Received",
        "NOA received": "NOA received",
        "NOA received after": "NOA received after",
        "NOA received before": "NOA received before",
        "NOA received between 20h - 24h ago, no DLV received": "NOA received between 20h - 24h ago, no DLV received",
        "Noa received between 20h - 24h ago, no dlv received": "Noa received between 20h - 24h ago, no dlv received",
        "NOAs": "NOAs",
        "Noa settings": "Noa settings",
        "NOA Time": "NOA Time",
        "NOA time": "NOA time",
        "Noa time": "Noa time",
        "NOA time(s)": "NOA time(s)",
        "Noa time from": "Noa time from",
        "Noa time to": "Noa time to",
        "No AWB available": "No AWB available",
        "No comments yet": "No comments yet",
        "No DLV times": "No DLV times",
        "No favorites yet": "No favorites yet",
        "No files": "No files",
        "No file uploaded": "No file uploaded",
        "No items": "No items",
        "No known excel upload time": "No known excel upload time",
        "No known opening hours for this day": "No known opening hours for this day",
        "No Loading List yet": "No Loading List yet",
        "No message": "No message",
        "None": "None",
        "No NOA times": "No NOA times",
        "No orders yet": "No orders yet",
        "No packages left": "No packages left",
        "No products found. Try again": "No products found. Try again",
        "No RCF times": "No RCF times",
        "No requirement": "No requirement",
        "No results": "No results",
        "No results found": "No results found",
        "No specific requirements": "No specific requirements",
        "No stacks left": "No stacks left",
        "Not all NOAs are available": "Not all NOAs are available",
        "Not available": "Not available",
        "Not available in this combination": "Not available in this combination",
        "Not Delivered": "Not Delivered",
        "Not editable after creation.": "Not editable after creation.",
        "Notes": "Notes",
        "Notes where created while in concept, might be out of date.": "Notes where created while in concept, might be out of date.",
        "Not Found": "Not Found",
        "Notification(s) of Arrival": "Notification(s) of Arrival",
        "Notification preference": "Notification preference",
        "Notifications": "Notifications",
        "Notify these email addresses": "Notify these email addresses",
        "Not implemented yet": "Not implemented yet",
        "No trigger": "No trigger",
        "No trips found": "No trips found",
        "No update": "No update",
        "No warehouse assigned": "No warehouse assigned",
        "Number": "Number",
        "Number of addresses": "Number of addresses",
        "Numbers": "Numbers",
        "of": "of",
        "Old loading list": "Old loading list",
        "Old URL": "Old URL",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.",
        "Only use this feature when driver is not allowed onto the premises and planner is not available to send one.": "Only use this feature when driver is not allowed onto the premises and planner is not available to send one.",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Open": "Open",
        "Opening hours": "Opening hours",
        "Opening hours: {from} - {till}": "Opening hours: {from} - {till}",
        "Openings hours": "Openings hours",
        "Open in new tab": "Open in new tab",
        "Open map": "Open map",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "Options": "Options",
        "Orange Button": "Orange Button",
        "Order": "Order",
        "Order now": "Order now",
        "Order number": "Order number",
        "Orders": "Orders",
        "Order was created": "Order was created",
        "Outbound scanned": "Outbound scanned",
        "Overloaded": "Overloaded",
        "Overview": "Overview",
        "Owner": "Owner",
        "Package": "Package",
        "package": "package",
        "Packages": "Packages",
        "Packages as on {number}": "Packages as on {number}",
        "Packages making up {number}": "Packages making up {number}",
        "Packages of {number}": "Packages of {number}",
        "Package type": "Package type",
        "Page": "Page",
        "Page blocks": "Page blocks",
        "Page block settings": "Page block settings",
        "Page Content": "Page Content",
        "Page Expired": "Page Expired",
        "Page link": "Page link",
        "Page not found.": "Page not found.",
        "Pages": "Pages",
        "Page Title": "Page Title",
        "Pagination Navigation": "Pagination Navigation",
        "Paid": "Paid",
        "Pallet": "Pallet",
        "Pallet: {number}": "Pallet: {number}",
        "Pallet {number}": "Pallet {number}",
        "Pallet number": "Pallet number",
        "Pallets": "Pallets",
        "Pallets to combine": "Pallets to combine",
        "Paragraph": "Paragraph",
        "Parcel": "Parcel",
        "Parcel: {number}": "Parcel: {number}",
        "Parcels": "Parcels",
        "Parent category": "Parent category",
        "Partially not delivered": "Partially not delivered",
        "Password": "Password",
        "Password reset": "Password reset",
        "Password reset link has been sent": "Password reset link has been sent",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone number.": "Paste or type the code we sent a code to your phone number.",
        "Payment": "Payment",
        "Payment Required": "Payment Required",
        "Payments": "Payments",
        "Pending Team Invitations": "Pending Team Invitations",
        "Pentecost": "Pentecost",
        "Pentecost monday": "Pentecost monday",
        "Percentage": "Percentage",
        "Perfecte pasvorm": "Perfecte pasvorm",
        "Perfect fit": "Perfect fit",
        "Permanent": "Permanent",
        "Permanently delete this team.": "Permanently delete this team.",
        "Permanently delete your account.": "Permanently delete your account.",
        "Permissions": "Permissions",
        "Phone": "Phone",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "phone_number": "phone_number",
        "Photo": "Photo",
        "Pickup": "Pickup",
        "Pickup: {number}": "Pickup: {number}",
        "Pickup {number}": "Pickup {number}",
        "Pickups": "Pickups",
        "Piece": "Piece",
        "Piece: {number}": "Piece: {number}",
        "Piece number": "Piece number",
        "Pieces": "Pieces",
        "Pieces #": "Pieces #",
        "Pieces of air waybill total": "Pieces of air waybill total",
        "Pieces of package total": "Pieces of package total",
        "Pieces on {airWaybillNumber}": "Pieces on {airWaybillNumber}",
        "Place order": "Place order",
        "Planned": "Planned",
        "Planned Loading": "Planned Loading",
        "Planned Loading Time": "Planned Loading Time",
        "Planned loading time": "Planned loading time",
        "Planning instructions": "Planning instructions",
        "Please check loading meters, this wouldn’t fit by {meters}": "Please check loading meters, this wouldn’t fit by {meters}",
        "Please check loading meters, this wouldn’t fit by {meters} m": "Please check loading meters, this wouldn’t fit by {meters} m",
        "Please check the following AWB’s to ensure cargo will be ready for pickup on time:": "Please check the following AWB’s to ensure cargo will be ready for pickup on time:",
        "Please claim this trip if appropriate": "Please claim this trip if appropriate",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Please confirm access to your account by entering one of your emergency recovery codes.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Please confirm access to your account by entering the authentication code provided by your authenticator application.",
        "Please confirm your understanding of the requirements for this trip.": "Please confirm your understanding of the requirements for this trip.",
        "Please copy your new API token. For your security, it won't be shown again.": "Please copy your new API token. For your security, it won't be shown again.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.",
        "Please let us know by adding a message below so we can send you the order directly if possible!": "Please let us know by adding a message below so we can send you the order directly if possible!",
        "Please note this notification is rate limited. Should this error happen again in the next {number} minutes, you will not be notified again. However the error will be logged in flare.": "Please note this notification is rate limited. Should this error happen again in the next {number} minutes, you will not be notified again. However the error will be logged in flare.",
        "Please provide the email address of the person you would like to add to this team.": "Please provide the email address of the person you would like to add to this team.",
        "Please set your password to activate your account.": "Please set your password to activate your account.",
        "Please use the link in your email to view your order.": "Please use the link in your email to view your order.",
        "PMCs": "PMCs",
        "Possible Noa": "Possible Noa",
        "Postal code": "Postal code",
        "Postcode": "Postcode",
        "Preloader": "Preloader",
        "Previous": "Previous",
        "Previously a loading list was send to {handlerNames}.": "Previously a loading list was send to {handlerNames}.",
        "Price": "Price",
        "Price: High - Low": "Price: High - Low",
        "Price: Low - High": "Price: Low - High",
        "Print": "Print",
        "Prioritized": "Prioritized",
        "Prioritized Air Waybills": "Prioritized Air Waybills",
        "Priority": "Priority",
        "Privacy declaration": "Privacy declaration",
        "Privacy page": "Privacy page",
        "Privacy Policy": "Privacy Policy",
        "Product": "Product",
        "Product details": "Product details",
        "Product information": "Product information",
        "Product option": "Product option",
        "Product options": "Product options",
        "Products": "Products",
        "Product Settings": "Product Settings",
        "Product slider": "Product slider",
        "Profile": "Profile",
        "Profile Information": "Profile Information",
        "Publish date": "Publish date",
        "Published At": "Published At",
        "Published from": "Published from",
        "Published until": "Published until",
        "Q": "Q",
        "QLS Data Export -": "QLS Data Export -",
        "Quantity": "Quantity",
        "Question": "Question",
        "Randomize image": "Randomize image",
        "RCF": "RCF",
        "RCF / DLV message": "RCF / DLV message",
        "RCF / DLV warehouse": "RCF / DLV warehouse",
        "RCF Handler": "RCF Handler",
        "RCF pieces": "RCF pieces",
        "RCF Time": "RCF Time",
        "RCF time": "RCF time",
        "RCF time(s)": "RCF time(s)",
        "Reactivate": "Reactivate",
        "Read more": "Read more",
        "Received at": "Received at",
        "Recovery Code": "Recovery Code",
        "Redirect": "Redirect",
        "Redirects": "Redirects",
        "Reference": "Reference",
        "Regards": "Regards",
        "Regards,": "Regards,",
        "Regenerate Recovery Codes": "Regenerate Recovery Codes",
        "Register": "Register",
        "Register to your account in our secure environment": "Register to your account in our secure environment",
        "Related products": "Related products",
        "Release note": "Release note",
        "Release notes": "Release notes",
        "Remarks": "Remarks",
        "Remember me": "Remember me",
        "Remove": "Remove",
        "Remove discount code": "Remove discount code",
        "Remove from favorites": "Remove from favorites",
        "remove one": "remove one",
        "Remove Photo": "Remove Photo",
        "remove product": "remove product",
        "Remove Stop #": "Remove Stop #",
        "Remove Team Member": "Remove Team Member",
        "Remove trip": "Remove trip",
        "Reopened subtask": "Reopened subtask",
        "Reportable": "Reportable",
        "Reported at": "Reported at",
        "Requested Loading": "Requested Loading",
        "Requested loading time": "Requested loading time",
        "Requested time": "Requested time",
        "Request RCF overview": "Request RCF overview",
        "Request Rcf Overview": "Request Rcf Overview",
        "Request report": "Request report",
        "Request trip": "Request trip",
        "Request trip anyway": "Request trip anyway",
        "Request warehouse RCF overview": "Request warehouse RCF overview",
        "Resend": "Resend",
        "Resend {attribute}": "Resend {attribute}",
        "Resend in": "Resend in",
        "Resend Verification Email": "Resend Verification Email",
        "Reserved By": "Reserved By",
        "Reserve truck": "Reserve truck",
        "Reset all filters": "Reset all filters",
        "Reset Password": "Reset Password",
        "Reset password": "Reset password",
        "Reset Password Notification": "Reset Password Notification",
        "Results": "results",
        "results": "results",
        "Results are shared": "Results are shared",
        "Reviews": "Reviews",
        "RGB color": "RGB color",
        "Role": "Role",
        "role": "role",
        "Roles": "Roles",
        "Route": "Route",
        "Row #": "Row #",
        "Sampled at": "Sampled at",
        "Saturday": "Saturday",
        "Save": "Save",
        "Save and add to new trip": "Save and add to new trip",
        "Save and request trip": "Save and request trip",
        "Saved.": "Saved.",
        "Save in favorites": "Save in favorites",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Scan": "Scan",
        "Scanning not possible?": "Scanning not possible?",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Scan the QR code": "Scan the QR code",
        "Search": "Search",
        "Search air waybills": "Search air waybills",
        "Search for pallet to combine": "Search for pallet to combine",
        "sec": "sec",
        "Select": "Select",
        "Select {model}": "Select {model}",
        "Select a {model}": "Select a {model}",
        "Select a {model} to add them": "Select a {model} to add them",
        "Select a air waybill": "Select a air waybill",
        "Select address": "Select address",
        "Select a flight": "Select a flight",
        "Select a handler": "Select a handler",
        "Select air waybill": "Select air waybill",
        "Select a location": "Select a location",
        "Select an address": "Select an address",
        "Select a network": "Select a network",
        "Select A New Photo": "Select A New Photo",
        "Select an option": "Select an option",
        "Select any day in the week you want a report on": "Select any day in the week you want a report on",
        "Select a package": "Select a package",
        "Select a package to add": "Select a package to add",
        "Select a package type to add": "Select a package type to add",
        "Select a page": "Select a page",
        "Select a pre-defined filter": "Select a pre-defined filter",
        "Select a preloader": "Select a preloader",
        "Select a reportable": "Select a reportable",
        "Select a role": "Select a role",
        "Select a stack to add": "Select a stack to add",
        "Select a status": "Select a status",
        "Select a template to add the corresponding blocks.": "Select a template to add the corresponding blocks.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Select a test": "Select a test",
        "Select a trailer type": "Select a trailer type",
        "Select a transporter": "Select a transporter",
        "Select a trip": "Select a trip",
        "Select a truck": "Select a truck",
        "Select a type": "Select a type",
        "Select a user": "Select a user",
        "Select a warehouse": "Select a warehouse",
        "Select a warehouse address": "Select a warehouse address",
        "Select AWB": "Select AWB",
        "Select package type": "Select package type",
        "Select role": "Select role",
        "Select trip": "Select trip",
        "Select truck": "Select truck",
        "Select user": "Select user",
        "Select warehouse": "Select warehouse",
        "Select yes or no": "Select yes or no",
        "Send": "Send",
        "Send and return": "Send and return",
        "Send a password reset link": "Send a password reset link",
        "Send e-mail": "Send e-mail",
        "Send email": "Send email",
        "Send link": "Send link",
        "Send to Exact": "Send to Exact",
        "Send verification {attribute}": "Send verification {attribute}",
        "Seo Settings": "Seo Settings",
        "SEO title": "SEO title",
        "Separate aliases with commas": "Separate aliases with commas",
        "Server Error": "Server Error",
        "Service": "Service",
        "Service Unavailable": "Service Unavailable",
        "Set as active": "Set as active",
        "Set as inactive": "Set as inactive",
        "Set  password": "Set  password",
        "Sets": "Sets",
        "Settings": "Settings",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "Setup Key": "Setup Key",
        "Set your password": "Set your password",
        "Set your secure password below": "Set your secure password below",
        "Sex": "Sex",
        "shared": "shared",
        "Share results": "Share results",
        "Shipments": "Shipments",
        "Shipping address": "Shipping address",
        "Shipping address same as billing address": "Shipping address same as billing address",
        "Shipping and return information": "Shipping and return information",
        "Shipping cost": "Shipping cost",
        "Shipping method": "Shipping method",
        "Shipping Rate": "Shipping Rate",
        "shipping rate": "shipping rate",
        "Shipping Rates": "Shipping Rates",
        "Shop": "Shop",
        "Shopping cart": "Shopping cart",
        "Shopping cart page": "Shopping cart page",
        "Short name": "Short name",
        "Should you wish continue with the change, without sending this message, press \"Update silently\"": "Should you wish continue with the change, without sending this message, press \"Update silently",
        "Show completed": "Show completed",
        "Showing": "Showing",
        "Show less": "Show less",
        "Show more": "Show more",
        "Show Recovery Codes": "Show Recovery Codes",
        "sidebar": "sidebar",
        "Sign out": "Sign out",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.",
        "Single ULD Cost": "Single ULD Cost",
        "Size chart image": "Size chart image",
        "Slides": "Slides",
        "Slug": "Slug",
        "Some of the air waybills do not have a NOA.": "Some of the air waybills do not have a NOA.",
        "Some of the shipments do not have a requested loading time.": "Some of the shipments do not have a requested loading time.",
        "Sort": "Sort",
        "Stack": "Stack",
        "stack": "stack",
        "Stacks": "Stacks",
        "Stacks as on {number}": "Stacks as on {number}",
        "Stacks on {number}": "Stacks on {number}",
        "stack trip": "stack trip",
        "Standard": "Standard",
        "Start": "Start",
        "Started at": "Started at",
        "Started inbound scan": "Started inbound scan",
        "Started outbound scan": "Started outbound scan",
        "Start new pallet": "Start new pallet",
        "Start time {day}": "Start time {day}",
        "Status": "Status",
        "status": "Status",
        "Statuses": "Statuses",
        "Stock": "Stock",
        "Stops": "Stops",
        "Stops on {number}": "Stops on {number}",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Subject": "Subject",
        "Sub menu": "Sub menu",
        "Subscribe": "Subscribe",
        "Subscribe for our newsletter": "Subscribe for our newsletter",
        "SubTasks": "SubTasks",
        "Subtotal": "Subtotal",
        "Sunday": "Sunday",
        "Swissport has undelivered air waybills for trip {trip}": "Swissport has undelivered air waybills for trip {trip}",
        "Switch Teams": "Switch Teams",
        "Sync": "Sync",
        "T1 cost": "T1 cost",
        "T1 email addresses": "T1 email addresses",
        "T1 phone number": "T1 phone number",
        "T1 received": "T1 received",
        "T1 settings": "T1 settings",
        "Tags": "Tags",
        "Task": "Task",
        "Tasks": "Tasks",
        "Team Details": "Team Details",
        "Team Invitation": "Team Invitation",
        "Team Members": "Team Members",
        "Team Name": "Team Name",
        "Team Owner": "Team Owner",
        "Team Settings": "Team Settings",
        "Tel": "Tel",
        "Terms and Conditions": "Terms and Conditions",
        "Terms of Service": "Terms of Service",
        "Terms page": "Terms page",
        "Test": "Test",
        "test": "test",
        "Test Notification": "Test Notification",
        "Tests": "Tests",
        "Test set": "Test set",
        "test set": "test set",
        "Test sets": "Test sets",
        "Text": "Text",
        "Thank you for shopping with us!": "Thank you for shopping with us!",
        "Thank you for your message.": "Thank you for your message.",
        "Thank you page settings": "Thank you page settings",
        "The {attribute} has already been taken.": "The {attribute} has already been taken.",
        "The {attribute} may only have two decimal places.": "The {attribute} may only have two decimal places.",
        "The {attribute} must be at least {length} characters.": "The {attribute} must be at least {length} characters.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "The {attribute} must be at least {length} characters and contain at least one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "The {attribute} must be at least {length} characters and contain at least one special character.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "The {attribute} must be at least {length} characters and contain at least one special character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.",
        "The {attribute} must be a valid role.": "The {attribute} must be a valid role.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The air waybill {awbNumber} is missing the RCF message. While it's trip {tripNumber} was delivered": "The air waybill {awbNumber} is missing the RCF message. While it's trip {tripNumber} was delivered",
        "The air waybill {number} is missing the RCF message": "The air waybill {number} is missing the RCF message",
        "The changes were:": "The changes were:",
        "The coupon has already been used.": "The coupon has already been used.",
        "The coupon has expired.": "The coupon has expired.",
        "The entire trip needs to start or end at the same address.": "The entire trip needs to start or end at the same address.",
        "The first shipment requires a arrival time of {datetime}": "The first shipment requires a arrival time of {datetime}",
        "The flight {flight} was delayed and will arrive at {newArrivalTime}": "The flight {flight} was delayed and will arrive at {newArrivalTime}",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The latest trends": "The latest trends",
        "Them: {theirNumber}": "Them: {theirNumber}",
        "The minimum height requirement for this trip is {height} cm": "The minimum height requirement for this trip is {height} cm",
        "The password field is required when creating an account.": "The password field is required when creating an account.",
        "The password is incorrect.": "The password is incorrect.",
        "The provided {attribute} is invalid.": "The provided {attribute} is invalid.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided password was incorrect.": "The provided password was incorrect.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The provided two factor recovery code was invalid.": "The provided two factor recovery code was invalid.",
        "There has been a new incident report created for a {model}.": "There has been a new incident report created for a {model}.",
        "There is a trip planned with reference {reference}, for loading on {time}": "There is a trip planned with reference {reference}, for loading on {time}",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.",
        "The status of trip {number} has been updated to {status}": "The status of trip {number} has been updated to {status}",
        "The team's name and owner information.": "The team's name and owner information.",
        "The tests cannot be changed once the test set is created.": "The tests cannot be changed once the test set is created.",
        "The trailer type for this trip is {type}": "The trailer type for this trip is {type}",
        "The trip will be updated, please check it afterwards.": "The trip will be updated, please check it afterwards.",
        "The ULD code is already in use for this air waybill.": "The ULD code is already in use for this air waybill.",
        "The ULD code is already in use for this trip.": "The ULD code is already in use for this trip.",
        "The ULD code is already in use of a package of the same type for this trip.": "The ULD code is already in use of a package of the same type for this trip.",
        "The weekly RCF overview.": "The weekly RCF overview.",
        "This {attribute} has already been used": "This {attribute} has already been used",
        "This action is unauthorized.": "This action is unauthorized.",
        "This area is restricted to medical medic members only.": "This area is restricted to medical medic members only.",
        "This area is restricted to staff members only.": "This area is restricted to staff members only.",
        "This concerns air waybills numbers {airWaybillNumber}": "This concerns air waybills numbers {airWaybillNumber}",
        "This concerns trip numbers {trips}": "This concerns trip numbers {trips}",
        "This device": "This device",
        "This flight has no known positions": "This flight has no known positions",
        "This is a secure area of the application. Please confirm your password before continuing.": "This is a secure area of the application. Please confirm your password before continuing.",
        "This is a test notification, with a very long body to test the notification system. Proin et auctor magna, quis semper tortor. In tristique nunc nec scelerisque porttitor. Suspendisse eu sem ut arcu tempus convallis et eu neque. Quisque fermentum, eros ac facilisis tempus, nulla dui bibendum lacus, at dignissim neque quam at nisl. Curabitur malesuada vel neque ut faucibus. Praesent consectetur orci tellus, eget rhoncus orci varius vel. Maecenas tempor lorem lorem. Nunc tellus magna, tincidunt id ante a, luctus sollicitudin nisl. Aenean ut leo sed urna cursus tristique. Nam et sodales risus. In nunc lectus, tincidunt id commodo quis, facilisis vel tortor. Morbi lectus nibh, condimentum eu molestie sed, interdum id est. Donec sit amet urna auctor, ultrices nibh sed, molestie ex. Nulla dolor lacus, tempus vel accumsan sed, tincidunt non arcu.": "This is a test notification, with a very long body to test the notification system. Proin et auctor magna, quis semper tortor. In tristique nunc nec scelerisque porttitor. Suspendisse eu sem ut arcu tempus convallis et eu neque. Quisque fermentum, eros ac facilisis tempus, nulla dui bibendum lacus, at dignissim neque quam at nisl. Curabitur malesuada vel neque ut faucibus. Praesent consectetur orci tellus, eget rhoncus orci varius vel. Maecenas tempor lorem lorem. Nunc tellus magna, tincidunt id ante a, luctus sollicitudin nisl. Aenean ut leo sed urna cursus tristique. Nam et sodales risus. In nunc lectus, tincidunt id commodo quis, facilisis vel tortor. Morbi lectus nibh, condimentum eu molestie sed, interdum id est. Donec sit amet urna auctor, ultrices nibh sed, molestie ex. Nulla dolor lacus, tempus vel accumsan sed, tincidunt non arcu.",
        "This is your first login": "This is your first login",
        "This link has expired, a new one has been sent to your email.": "This link has expired, a new one has been sent to your email.",
        "This password does not match our records.": "This password does not match our records.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This shipping rate is not applicable to your cart.": "This shipping rate is not applicable to your cart.",
        "This task is reserved by {userName}": "This task is reserved by {userName}",
        "This task is reserved by {userName}.": "This task is reserved by {userName}.",
        "This trip has no known positions": "This trip has no known positions",
        "This trip is not yet claimed by anyone.": "This trip is not yet claimed by anyone.",
        "This user already belongs to the team.": "This user already belongs to the team.",
        "This user has already been invited to the team.": "This user has already been invited to the team.",
        "This will move the air waybill included packages and copy over flight to the selected location.": "This will move the air waybill included packages and copy over flight to the selected location.",
        "This will move the stack to the selected handler.": "This will move the stack to the selected handler.",
        "This will move the stack to the selected location.": "This will move the stack to the selected location.",
        "This will overwrite the current ULD codes": "This will overwrite the current ULD codes",
        "Thursday": "Thursday",
        "Tiktoc link": "Tiktoc link",
        "Till": "Till",
        "Times": "Times",
        "Times used": "Times used",
        "Title": "Title",
        "title": "title",
        "To": "To",
        "to": "to",
        "To addresses": "To addresses",
        "TODO": "TODO",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Token Name": "Token Name",
        "Too Many Requests": "Too Many Requests",
        "To Status": "To Status",
        "Totaal products ({number})": "Totaal products ({number})",
        "Total": "Total",
        "Total saving": "Total saving",
        "Trailer": "Trailer",
        "Trailer type": "Trailer type",
        "Trailer type: {type}": "Trailer type: {type}",
        "Trailer type of {type}": "Trailer type of {type}",
        "Trans": "Trans",
        "Transit document mail addresses": "Transit document mail addresses",
        "Transit document phone numbers": "Transit document phone numbers",
        "Transit documents": "Transit documents",
        "Transit documents notification text": "Transit documents notification text",
        "Transporter": "Transporter",
        "transporter": "transporter",
        "Transporter Companies": "Transporter Companies",
        "transporter companies": "transporter companies",
        "Transporters": "Transporters",
        "Transport instructions": "Transport instructions",
        "Trip": "Trip",
        "trip": "Trip",
        "Trip {number} started loading at {time} was planned to pickup the following AWB’s:": "Trip {number} started loading at {time} was planned to pickup the following AWB’s:",
        "Trip cancellation": "Trip cancellation",
        "Trip from date": "Trip from date",
        "Trip from status": "Trip from status",
        "Triple ULD Cost": "Triple ULD Cost",
        "Trip Overview": "Trip Overview",
        "Trips": "Trips",
        "trips": "Trips",
        "Trip Status": "Trip Status",
        "Trip status updated": "Trip status updated",
        "Trip to date": "Trip to date",
        "Trip to status": "Trip to status",
        "Trip type": "Trip type",
        "Trip was changed since last update to third parties": "Trip was changed since last update to third parties",
        "Trip was finalized at {time}": "Trip was finalized at {time}",
        "Truck": "Truck",
        "Truck {number} was requested for {requestedTime} but planned for {plannedTime}. The difference is {hours} hours and {minutes} minutes.": "Truck {number} was requested for {requestedTime} but planned for {plannedTime}. The difference is {hours} hours and {minutes} minutes.",
        "Truck hasn't left yet": "Truck hasn't left yet",
        "Truck is standing still": "Truck is standing still",
        "Truck planned later than requested time": "Truck planned later than requested time",
        "Tuesday": "Tuesday",
        "Two-factor Confirmation": "Two-factor Confirmation",
        "Two Factor Authentication": "Two Factor Authentication",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.",
        "Two Factor Authentication is required as extra security, you can set this now": "Two Factor Authentication is required as extra security, you can set this now",
        "Two factor challenge": "Two factor challenge",
        "Type": "Type",
        "ULD": "ULD",
        "Uld": "Uld",
        "ULD code": "ULD code",
        "Uld code": "Uld code",
        "ULD codes": "ULD codes",
        "Ulds": "Ulds",
        "Unassigned": "Unassigned",
        "Unauthorized": "Unauthorized",
        "Unisex": "Unisex",
        "Unit price": "Unit price",
        "Unknown": "Unknown",
        "Unloaded Excel": "Unloaded Excel",
        "Unloading": "Unloading",
        "Unloading time": "Unloading time",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Unresolved incident report": "Unresolved incident report",
        "Up": "Up",
        "Update": "Update",
        "Update {model}": "Update {model}",
        "Update account": "Update account",
        "Update anyway": "Update anyway",
        "Update comment": "Update comment",
        "updated": "updated",
        "Updated at: {time}": "Updated at: {time}",
        "Updated by {name}": "Updated by {name}",
        "Update package": "Update package",
        "Update package {uldCode}": "Update package {uldCode}",
        "Update Password": "Update Password",
        "Update shipment": "Update shipment",
        "Update silently": "Update silently",
        "Update stack": "Update stack",
        "Update trip": "Update trip",
        "Update warehouse": "Update warehouse",
        "Update your account's profile information and email address.": "Update your account's profile information and email address.",
        "Update your info here.": "Update your info here.",
        "Upload": "Upload",
        "Upload Excel": "Upload Excel",
        "Upload files": "Upload files",
        "Uploading T1’s was disabled for this handler to avoid sending unnecessary emails. If you want to enable this, please contact planning@qlshandling.com": "Uploading T1’s was disabled for this handler to avoid sending unnecessary emails. If you want to enable this, please contact planning{'@'}qlshandling.com",
        "Url": "Url",
        "url": "url",
        "Us: {number}, them: {theirNumber}": "Us: {number}, them: {theirNumber}",
        "Use an authentication code": "Use an authentication code",
        "Use a recovery code": "Use a recovery code",
        "Used in reference and such": "Used in reference and such",
        "User": "User",
        "user": "user",
        "Users": "Users",
        "Use `-1` for unlimited usage": "Use `-1` for unlimited usage",
        "USPs": "USPs",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Value": "Value",
        "Values": "Values",
        "Variant": "Variant",
        "Variants": "Variants",
        "VAT": "VAT",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify Email Address": "Verify Email Address",
        "Verify your phone number": "Verify your phone number",
        "View incident": "View incident",
        "View order": "View order",
        "View trip": "View trip",
        "Waiting time": "Waiting time",
        "Waiting Time Per Hour Cost": "Waiting Time Per Hour Cost",
        "Warehouse": "Warehouse",
        "warehouse": "warehouse",
        "Warehouse DLV": "Warehouse DLV",
        "Warehouse Overview": "Warehouse Overview",
        "Warehouse RCF": "Warehouse RCF",
        "Warehouses": "Warehouses",
        "Warning: limited stock!": "Warning: limited stock!",
        "Was claimed through marketplace": "Was claimed through marketplace",
        "Washing instructions": "Washing instructions",
        "Was send to Exact": "Was send to Exact",
        "Was unable to find an address with this postcode and street number": "Was unable to find an address with this postcode and street number",
        "Web Page": "Web Page",
        "Web Page Settings": "Web Page Settings",
        "Website": "Website",
        "Wednesday": "Wednesday",
        "Weekly Rcf Overview {date}": "Weekly Rcf Overview {date}",
        "Week of": "Week of",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "Weight": "Weight",
        "Welcome": "Welcome",
        "Welcome back": "Welcome back",
        "Welcome to {page}": "Welcome to {page}",
        "Welcome to QLS handling": "Welcome to QLS handling",
        "We were unable to find a registered user with this email address.": "We were unable to find a registered user with this email address.",
        "What is my size?": "What is my size?",
        "When a NOA for an unknown air waybill is send to one of these email addresses, notify the other email addresses": "When a NOA for an unknown air waybill is send to one of these email addresses, notify the other email addresses",
        "When a unknown NOA is send to one of these email addresses": "When a unknown NOA is send to one of these email addresses",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "When enabled and the handler has a t1 cost set, T1 will be included on the invoice.": "When enabled and the handler has a t1 cost set, T1 will be included on the invoice.",
        "When NOA is found for unknown air waybill number, which has these email addresses in the \\'From\\' field, notify the email addresses in \\'Email addresses to notify when NOA is found for unknown air waybill number\\' field. Separate multiple email addresses with a comma.": "When NOA is found for unknown air waybill number, which has these email addresses in the \\'From\\' field, notify the email addresses in \\'Email addresses to notify when NOA is found for unknown air waybill number\\' field. Separate multiple email addresses with a comma.",
        "When set and the location setting is enabled, T1 cost will be included on the invoice.": "When set and the location setting is enabled, T1 cost will be included on the invoice.",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.",
        "Whoops!": "Whoops!",
        "Whoops! Something went wrong.": "Whoops! Something went wrong.",
        "Yes": "Yes",
        "Yes at {dateTime}": "Yes at {dateTime}",
        "You account has been created.": "You account has been created.",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You are subscribed to the newsletter": "You are subscribed to the newsletter",
        "You cannot cancel a trip with cargo. Please remove the cargo first.": "You cannot cancel a trip with cargo. Please remove the cargo first.",
        "You have been invited to join the {team} team!": "You have been invited to join the {team} team!",
        "You have changed the status of the trip to \"cancelled\". A message will be send ot the transporter cancelling the trip.": "You have changed the status of the trip to \"cancelled\". A message will be send ot the transporter cancelling the trip.",
        "You have changed the transporter of the trip. A message will be send ot the original transporter cancelling the trip.": "You have changed the transporter of the trip. A message will be send ot the original transporter cancelling the trip.",
        "You have enabled two factor authentication.": "You have enabled two factor authentication.",
        "You have not enabled two factor authentication.": "You have not enabled two factor authentication.",
        "You may accept this invitation by clicking the button below:": "You may accept this invitation by clicking the button below:",
        "You may delete any of your existing tokens if they are no longer needed.": "You may delete any of your existing tokens if they are no longer needed.",
        "You may not delete your personal team.": "You may not delete your personal team.",
        "You may not leave a team that you created.": "You may not leave a team that you created.",
        "Your account": "Your account",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is inactive. Please contact support.": "Your account is inactive. Please contact support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.",
        "Your account is not active. Please contact support.": "Your account is not active. Please contact support.",
        "Your current locale is {locale}": "Your current locale is {locale}",
        "Your email address is unverified.": "Your email address is unverified.",
        "Your last login was {relativeTime} on {dateTime}": "Your last login was {relativeTime} on {dateTime}",
        "Your Order": "Your Order",
        "Your order": "Your order",
        "Your order was created.": "Your order was created.",
        "Your Profile": "Your Profile",
        "Your shopping cart is empty": "Your shopping cart is empty",
        "Youtube link": "Youtube link",
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "accepted_if": "The {attribute} must be accepted when {other} is {value}.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} must only contain letters.",
            "alpha_dash": "The {attribute} must only contain letters, numbers, dashes and underscores.",
            "alpha_num": "The {attribute} must only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} must have between {min} and {max} items.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} must be between {min} and {max}.",
                "string": "The {attribute} must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} confirmation does not match.",
            "contains": "The {attribute} field is missing a required value.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} is not a valid date.",
            "date_equals": "The {attribute} must be a date equal to {date}.",
            "date_format": "The {attribute} does not match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} must be declined.",
            "declined_if": "The {attribute} must be declined when {other} is {value}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} must be a valid email address.",
            "ends_with": "The {attribute} must end with one of the following: {values}.",
            "enum": "The {attribute} field value is not in the list of allowed values.",
            "exists": "The {attribute} field value does not exist.",
            "extensions": "The {attribute} field must have one of the following extensions: {values}.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} must have more than {value} items.",
                "file": "The {attribute} must be greater than {value} kilobytes.",
                "numeric": "The {attribute} must be greater than {value}.",
                "string": "The {attribute} must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} must have {value} items or more.",
                "file": "The {attribute} must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be greater than or equal to {value}.",
                "string": "The {attribute} must be greater than or equal to {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} must be an image.",
            "in": "The {attribute} field value is not in the list of allowed values.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "list": "The {attribute} field must be a list.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} must have less than {value} items.",
                "file": "The {attribute} must be less than {value} kilobytes.",
                "numeric": "The {attribute} must be less than {value}.",
                "string": "The {attribute} must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} must not have more than {value} items.",
                "file": "The {attribute} must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} must be less than or equal to {value}.",
                "string": "The {attribute} must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} must be a valid MAC address.",
            "max": {
                "array": "The {attribute} must not have more than {max} items.",
                "file": "The {attribute} must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} must not be greater than {max}.",
                "string": "The {attribute} must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} must have at least {min} items.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "numeric": "The {attribute} must be at least {min}.",
                "string": "The {attribute} must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} must be a multiple of {value}.",
            "not_in": "The {attribute} field must not be in the list.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_if_declined": "The {attribute} field is required when {other} is declined.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "array": "The {attribute} must contain {size} items.",
                "file": "The {attribute} must be {size} kilobytes.",
                "numeric": "The {attribute} must be {size}.",
                "string": "The {attribute} must be {size} characters."
            },
            "starts_with": "The {attribute} must start with one of the following: {values}.",
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid timezone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} must be a valid URL.",
            "uuid": "The {attribute} must be a valid UUID.",
            "attributes": {
                "address": "address",
                "address_id": "address",
                "age": "age",
                "air_waybill_id": "air waybill",
                "amount": "amount",
                "area": "area",
                "available": "available",
                "billing_address.city": "city",
                "billing_address.country": "country",
                "billing_address.postcode": "postcode",
                "billing_address.street_name": "street name",
                "billing_address.street_number": "street number",
                "billing_address.street_number_addition": "street number addition",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "consultant_id": "consultant",
                "content": "content",
                "country": "country",
                "created_at": "created at",
                "creator": "creator",
                "current_password": "current password",
                "date": "date",
                "date_of_birth": "date of birth",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "first_name": "first name",
                "gender": "gender",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "network_id": "network",
                "number": "number",
                "package_id": "package",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "postal_code": "postal code",
                "price": "price",
                "province": "province",
                "recaptcha_response_field": "recaptcha response field",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "role_id": "role",
                "second": "second",
                "sex": "sex",
                "shipping_address.city": "city",
                "shipping_address.country": "country",
                "shipping_address.postcode": "postcode",
                "shipping_address.street_name": "street name",
                "shipping_address.street_number": "street number",
                "shipping_address.street_number_addition": "street number addition",
                "shipping_rate_id": "shipping rate",
                "short_text": "short text",
                "size": "size",
                "state": "state",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "updated_at": "updated at",
                "username": "username",
                "year": "year"
            }
        },
        "passwords": {
            "reset": "Your password has been reset.",
            "sent": "We have emailed your password reset link.",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We have emailed your password reset link."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds."
        },
        "routes": {
            "products": "products",
            "blog": "blog",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact"
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        }
    }
}
